@import "../../../../../../Assets/Scss/primaryColor";

.DirectDebitApproval {
  margin-top: 17px;
  .page-title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #303030;
  }
  .page-description {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #6d6d6d;
  }
  .sub-nav {
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #dfe4ea;
    display: flex-start;
    flex-direction: row;
    align-items: center;

    @media (max-width: 500px) {
      overflow: scroll;
    }

    .nav-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #333333;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 24px;
      color: #787878;
      flex: none;
      order: 2;
      flex-grow: 0;
    }

    .active {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 0.9rem;
      background: #ffffff;
      color: $primary-estate;
      border-bottom: 2px solid $primary-estate;
    }
  }

  .button-container {
    position: relative;

    svg {
      margin-right: 5px;
    }

    .btn-edit {
      border: 1px solid $primary-estate;
      color: $primary-estate;
      border-radius: 20px;
      margin-right: 10px;
      font-size: 0.78rem;
    }

    .btn-subscribe {
      background-color: $primary-estate;
      color: $white;
      border-radius: 20px;
      font-size: 0.78rem;
    }
  }

  .btn-create-new {
    background-color: $primary-estate;
    color: #ffffff;
    font-size: 0.8rem;
    border-radius: 40px;
  }

  .bulk-order-title {
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 20px;
      color: #303030;
    }
    div {
      font-style: normal;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 150%;
      color: #606060;
    }
  }

  .project-status {
    background: rgba(237, 184, 46, 0.15);
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    color: #edb82e;
    padding: 5px 10px;
  }
  .project-details {
    width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .project-type {
    background: #f0f3fc;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #3a0ca3;
    padding: 5px 10px;
    text-align: center;
  }

  .seach-field {
    position: relative;

    @media (max-width: 800px) {
      margin-top: 20px;
    }

    .search-input {
      background: #ffffff;
      border: 1px solid #dfe4ea;
      border-radius: 100px;
      height: 40px;
      padding-left: 40px;

      &::placeholder {
        font-weight: 500;
        font-size: 0.73rem;
        line-height: 150%;
        color: #868484;
      }
    }
  }

  .search-icon {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .btn-filter {
    background: #ffffff;
    border: 1px solid #dfe4ea;
    border-radius: 100px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.76rem;
    line-height: 150%;
    color: #868484;
    height: 40px;
    width: 100%;

    @media (max-width: 800px) {
      margin-top: 20px;
    }

    .btn-icon {
      margin-right: 10px;
      width: 16px;
    }
  }

  .profile-avarter {
    display: inline-flex;
    height: 25px;
    width: 25px;
    justify-content: center;
    align-items: center;
    background: #e4e4e5;
    border-radius: 50px;
    margin-right: 4px;
    font-size: 0.8rem;
  }

  .enterprise-table {
    th {
      border-bottom: 1px solid rgb(199, 199, 199) !important;
    }
    td {
      border-bottom: 1px solid rgb(199, 199, 199) !important;
    }

    .table {
      border: 1px solid #dfe4ea;
      border-radius: 20px !important;
      margin-top: 20px;

      thead {
        background-color: #f0f0f0;
        th {
          font-style: normal;
          font-weight: 500;
          font-size: 0.77rem;
          line-height: 18px;
          color: rgba(76, 76, 76, 0.81);
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      tbody {
        td {
          font-style: normal;
          font-weight: 500;
          font-size: 0.78rem;
          line-height: 150%;
          color: #606060;
          vertical-align: middle;

          .created-status {
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(52, 186, 150, 0.15);
            border-radius: 100px;
            font-weight: 500;
          }

          .status-pending {
            font-weight: 400;
            font-size: 0.7rem;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #f5a623;
            flex: none;
            order: 0;
            flex-grow: 0;
            border: 1px solid #f5a623;
            border-radius: 20px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .dropdown-item {
            font-style: normal;
            font-weight: 400;
            font-size: 0.72rem;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }

        @media (max-width: 800px) {
          .profile-title {
          }

          .profile-detail {
            font-size: 0.74rem !important;
            line-height: 13px;
          }

          .profile-btn {
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn {
              border: 1px solid #dfe4ea;
              border-radius: 100px;
              font-style: normal;
              font-weight: 500;
              width: 60px;
            }
          }
        }
        @media (min-width: 800px) {
          .profile-detail {
            // font-size: .5rem !important;
            // line-height: 14px;
          }
        }
      }

      .table-profile {
        @media (max-width: 800px) {
          width: 55px;
          height: 55px;
          border-radius: 5px;
        }
        @media (min-width: 800px) {
          width: 28px;
          height: 28px;
          border-radius: 50px;
        }
      }

      .dropdown-toggle {
        &::after {
          display: none;
        }

        .d-icon {
          color: #606060;
          font-size: 0.9rem;
        }
      }
    }
  }

  .dropdown-menu {
    li {
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #333333;
    }
  }

  .bulk-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    .order-details {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #f5a623;
      height: 32px;
      border-radius: 100px;
      border: 1px solid #f5a623;
      margin: 10px 10px 10px 0;
    }

    .pay-now {
      width: 104px;
      height: 32px;
      background: #f5a623;
      border-radius: 100px;
      color: #dfe4ea;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 18px;
      color: #ffffff;
      margin: 10px;
    }
  }

  .pagination {
    position: relative;
    .pagination-item {
      background-color: #ffffff !important;
      border: 1px solid #ffffff;

      &.selected {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #f5a623 !important;
        background-color: #ffffff !important;
        border: 1px solid #ffffff;
      }
    }

    // .pagination-item:last-child{
    //     border: 1px solid grey;
    //     box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    //     border-radius: 50px;
    //     position: absolute;
    //     right: 0;

    //     .right{
    //         content: "\2192";
    //     }
    // }

    // .pagination-item:first-child{
    //     border: 1px solid grey;
    //     box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    //     border-radius: 50px;
    //     position: absolute;
    //     left: 0;

    // }
    .left::before {
    }
    .right::before {
    }
  }
}

.mandateDetails {
  ul.timeline {
    list-style-type: none;
    position: relative;
  }

  ul.timeline:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 26px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }

  ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
    font-size: 0.8rem;
  }

  ul.timeline > li:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #3a0ca3;
    left: 22px;
    width: 13px;
    height: 13px;
    z-index: 400;
  }

  .btn-create-new {
    background-color: $primary-estate;
    color: #ffffff;
    font-size: 0.8rem;
    border-radius: 40px;
  }
}
