.home-adsSection {
  @media (max-width: 600px) {
    padding-top: 20px;
  }
  @media (min-width: 600px) {
    padding-top: 50px;
  }
  .ads {
    margin-top: 20px;
    @media (max-width: 600px) {
      width: 100%;
    }
    @media (min-width: 600px) {
      width: 100%;
    }

    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
    border-radius: 8px;

    transition: all 0.5s ease-in-out;
    position: relative;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.341);
    }

    .ads-element {
      height: 50px;
      width: 80%;
      position: absolute;

      @media (max-width: 600px) {
        bottom: 0;
        left: 30px;
      }
      @media (min-width: 600px) {
        bottom: 20px;
        left: 30px;
      }
    }

    .card {
      background-color: #34ba96;
      height: 450px;

      .title {
        color: #ffffff;
        font-family: Lato;
        font-weight: 700;
        font-size: 33.29px;
        line-height: 100%;
        letter-spacing: -1%;
        margin-top: 40px;
      }

      .btn-getstarted {
        width: 191px;
        height: 50px;
        border-radius: 100px;
        border-width: 1px;
        background-color: #ffffff;
        color: #3378f3;
        font-weight: 600;
      }

      .description {
        color: #ffffff;
        font-family: Lato;
        font-weight: 700;
        font-size: 20px;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        height: 330px !important;
        .title {
          font-size: 1.3rem !important;
        }

        .description {
          font-size: 0.9rem !important;
        }
        .btn-getstarted {
          width: 131px;
          height: 40px;
        }
      }

      @media (min-width: 1199px) and (max-width: 1399px) {
        height: 390px !important;
        .title {
          font-size: 1.5rem !important;
        }

        .description {
          font-size: 1rem !important;
        }
      }

      @media (min-width: 768px) and (max-width: 992px) {
        height: 240px !important;
        .title {
          font-size: 0.8rem !important;
          margin-top: 10px;
        }

        .description {
          font-size: 0.6rem !important;
        }

        .btn-getstarted {
          width: 101px;
          height: 30px;
          font-size: 0.7rem;
          margin-top: 10px !important;
        }
      }
    }
  }
}
