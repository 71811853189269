.ApproveMandate {
  label {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  small {
    font-size: 0.7rem;
    margin: 0 15px;
  }
  span {
    font-size: 0.7rem;
    margin: 0 15px;
  }

  .form-control {
    background: #ffffff;
    border: 1px solid #dfe4ea;
    border-radius: 100px;
    height: 50px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-feature-settings: "liga" off;
    color: #828282;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  select {
    font-size: 0.8rem !important;
    option {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem !important;
      line-height: 18px;
      font-feature-settings: "liga" off;
      color: #828282;
    }
  }

  .btn {
    width: 100%;
    height: 42px;
    background: #3378f3;
    color: #dfe4ea;
    font-weight: 800;
    box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
    border-radius: 100px;
  }

  .btn-outline {
    width: 100%;
    height: 56px;
    font-weight: 800;
    color: #3378f3;
    background: #3379f300;
    box-shadow: 0px 2.5px 5px rgba(14, 14, 44, 0.15);
    border-radius: 100px;
  }
}
